// the ID of field to record the email address of the doctor who is having meeting with the patient
import { IRELAND, UNITED_KINGDOM } from "./constants";

export const OP_FIELD_ID = '023c7bca9a9383b6c72455de9c176fb6';
// the ID of field to record patient id
export const PATIENT_FIELD_ID = 'b6da6750a26b82a9726224966d82f4f3';
// the ID of field to record note left by the patient
export const NOTE_FIELD_ID = '67a5ab97576ee8262d417642d3fc39f0';
// the ID of field to record the consulting exam details
export const EXAM_REF_FIELD_ID = '3df169475ade5872be6e762334715c77';
// the ID of field to record patient local meeting time
export const LOCAL_MEETING_TIME_ID = 'ccff9209fb9f6b0db20ded83d4e4034d'
// the ID of field to record meeting url
export const MEETING_URL = '27e08c673ddcbb230900b6556fd0e699'
// the ID of field to record the region where book made
export const BOOK_REGION = 'b09413ff6d3730a98c26638fbd3c8a20'

// meeting status
export const COMPLETED = 'confirmed';
export const CANCELED = 'canceled';

// Video Conference Services ID
export const EYE_CONSULTATION_SERVICE_ID = 3;

export const AVAILABLE_MEETING_REGIONS = {
	'eu-west-1': IRELAND,
	'eu-west-2': UNITED_KINGDOM,

}

// Video Conference Service Duration
export const SERVICE_DURATION_NUM = {
	[IRELAND]: 30,
	[UNITED_KINGDOM]: 30,
};

export const CANCELLATION_BUFFER = {
	[IRELAND]: 1440,
	[UNITED_KINGDOM]: 1440,
}

export const TIMEZONES = {
	"Asia/Singapore": "Singapore, peninsular Malaysia",
	"Australia/Darwin": "Darwin",
	"Australia/Adelaide": "Adelaide",
	"Australia/Sydney": "Canberra, Melbourne, Sydney",
	"Australia/Brisbane": "Brisbane",
	"Australia/Hobart": "Hobart",
	"Australia/Perth": "Perth",
	"Europe/London" : "London"
}

export const DATE_FORMAT = 'dd/MM/yyyy HH:mm'
export const TELECONSULT_SYSTEM_TIMEZONE = "Europe/London"

export const TELECONSULT_INCLUSIONS_IE = {
	BOOKING_STEPS: ['Select Optometrists', 'Select Meeting Time', 'Review Details'],
	SERVICE_ID: 1,
	SERVICE_DURATION: 30, //Video Conference Service Duration minutes
	REGION: IRELAND
}

export const TELECONSULT_INCLUSIONS_GB = {
	BOOKING_STEPS: ['Select Optometrists', 'Select Meeting Time', 'Review Details'],
	SERVICE_ID: 4,
	SERVICE_DURATION: 30, //Video Conference Service Duration in minutes
	REGION: UNITED_KINGDOM
}

export const TELECONSULT_INCLUSIONS_TEST = {
	BOOKING_STEPS: ['Select Optometrists', 'Select Meeting Time', 'Review Details'],
	SERVICE_ID: 3,
	SERVICE_DURATION: 30, //Video Conference Service Duration in minutes,
	REGION: `${process.env.REACT_APP_AWS_REGION} ${process.env.REACT_APP_STAGE}`
}
