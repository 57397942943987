import {
    SUBSCRIPTION_INCLUSIONS_AU,
    SUBSCRIPTION_INCLUSIONS_DE, SUBSCRIPTION_INCLUSIONS_GB,
    SUBSCRIPTION_INCLUSIONS_IE, SUBSCRIPTION_INCLUSIONS_IN,
    SUBSCRIPTION_INCLUSIONS_SG
} from "../constants/subscriptions";
import {
    TELECONSULT_INCLUSIONS_IE,
    TELECONSULT_INCLUSIONS_GB,
    TELECONSULT_INCLUSIONS_TEST
} from "../constants/meetings";
import {
    AUSTRALIA,
    ETHNICS_STR,
    GERMANY, INDIA,
    IRELAND,
    SIGN_UP_FORM_FIELD_STR_ENUMS,
    SINGAPORE, UNITED_KINGDOM
} from "../constants/constants";

export const getDeployRegion = () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'ap-southeast-2': // sydney, australia (2)
            return AUSTRALIA;
        case 'ap-southeast-1': //singapore (1)
            return SINGAPORE;
        case 'eu-central-1 ': // frankfurt, germany
            return GERMANY;
        case 'eu-west-1': // Ireland
            return IRELAND;
        case 'eu-west-2': // GB Mainland
            return UNITED_KINGDOM;
        case 'ap-south-1': // Mumbai, India:
            return INDIA
        default:
            return console.error(`Deploy region not found ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const getRegionSpecificSubscriptionInclusions = () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'ap-southeast-2': // sydney, australia (2)
            return SUBSCRIPTION_INCLUSIONS_AU;
        case 'ap-southeast-1': //singapore (1)
            return SUBSCRIPTION_INCLUSIONS_SG;
        case 'eu-central-1 ': // frankfurt, germany
            return SUBSCRIPTION_INCLUSIONS_DE;
        case 'eu-west-1': // Ireland
            return SUBSCRIPTION_INCLUSIONS_IE;
        case 'eu-west-2': // GB Mainland
            return SUBSCRIPTION_INCLUSIONS_GB;
        case 'ap-south-1': // Mumbai, India:
            return SUBSCRIPTION_INCLUSIONS_IN;
        default:
            return console.error(`No subscription inclusions found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const getRegionSpecificMeetingInclusions = () => {
    if (['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)) {
        return TELECONSULT_INCLUSIONS_TEST; // for scheduling functionality test
    }
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'eu-west-1': // Ireland
            return TELECONSULT_INCLUSIONS_IE;
        case 'eu-west-2': // GB Mainland
            return TELECONSULT_INCLUSIONS_GB;
        default:
            return console.debug(`No meeting configuration found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const checkRegionEnabledTeleconsult = () => {
    switch (process.env.REACT_APP_AWS_REGION) {
        case 'eu-west-1': // Ireland
            return ['test', 'development', 'staging', 'production'].includes(process.env.REACT_APP_STAGE)
        case 'eu-west-2': // GB Mainland
            return ['test', 'development', 'staging'].includes(process.env.REACT_APP_STAGE)
        default:
            return false
    }
}

export const getRegionSpecificEthnicity = () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'ap-southeast-2': // sydney, australia (2)
            return ETHNICS_STR.AU;
        case 'ap-southeast-1': //singapore (1)
            return ETHNICS_STR.SG;
        case 'eu-central-1 ': // frankfurt, germany
            return ETHNICS_STR.DE;
        case 'eu-west-1': // ireland
            return ETHNICS_STR.IRELAND;
        case 'eu-west-2': // united kingdom
            return ETHNICS_STR.UNITED_KINGDOM;
        case 'ap-south-1': // mumbai, india
            return ETHNICS_STR.INDIA;
        default:
            return console.error(`No ethnicity configuration found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}

export const getRegionSpecificSignUpFormFieldConfigs= () => {
    switch(process.env.REACT_APP_AWS_REGION) {
        case 'eu-central-1 ': // frankfurt, germany
            return SIGN_UP_FORM_FIELD_STR_ENUMS[GERMANY];
        case 'ap-southeast-2': // sydney, australia (2)
            return SIGN_UP_FORM_FIELD_STR_ENUMS[AUSTRALIA];
        case 'ap-southeast-1': //singapore (1)
            return SIGN_UP_FORM_FIELD_STR_ENUMS[SINGAPORE];
        case 'eu-west-1': // Ireland
            return SIGN_UP_FORM_FIELD_STR_ENUMS[IRELAND];
        case 'eu-west-2': // GB Mainland
            return SIGN_UP_FORM_FIELD_STR_ENUMS[UNITED_KINGDOM];
        case 'ap-south-1': // Mumbai, India:
            return SIGN_UP_FORM_FIELD_STR_ENUMS[INDIA];
        default:
            return console.error(`No sign up form field configuration found for the region ${process.env.REACT_APP_AWS_REGION}`);
    }
}
